import breadcrumbComp from "../../common/breadcrumb-comp.vue";
// import Utility from "../../../shared/utility";
import EncryptUtility from "../../utility/js/encrypt-utility";
import AddFavourite from "../../utility/js/addFav";
import recevingService from "./receive-srvice";
import FilterComp from "../../common/filter-comp.vue";
import moment from "moment";
import utility from "@/shared/utility";
import DisplayTextData from "@/components/common/display-text-data.vue";
import ReadOnlyDialog from "@/components/common/read-only-dialog.vue";
export default {
  data() {
    return {
      userId: EncryptUtility.localStorageDecrypt("userID"),
      actionList: [],
      favouriteList: [],
      mainPage: "",
      subPage: "",
      backSlash: true,
      pageUrl: "",
      showAdd: false,
      enableAction: false,
      enableCancel: false,
      enableClear: false,
      disableNotesandPrevent:false,
      enableComments: false,
      enableGrid: false,
      resolutionList: [],
      resolution: "",
      enableOrder: false,
      disableStatus:true,
      enablePrevent: false,
      enablePurge: false,
      enableResolve: false,
      enableUpdate: false,
      orderStatus:"",
      loc:"",
      orderLineKey: 0,
      soId: 0,
      poId: 0,
      refresh: true,
      log_details:"",
      showClear: false,
      dialogHeader:"Show Tag Information",
      commonDialog:false,
      projectList: [],
      statusList: [],
      maxStartDate: new Date().toISOString().split("T")[0],
      minEndDate: new Date().toISOString().split("T")[0],
      maxEndDate: new Date(new Date().getTime()).toISOString().split("T")[0],
      showStartDate: true,
      showEndDate: true,
      startDate: false,
      line_id: "",
      disableResolution: true,
      preventable: false,
      notes: "",
      disableSearch: true,
      disableCancel:false,
      endDate: false,
      showDetails: false,
      tableItems:[],
      commonHeader:[
        {
          text: "Tag#",
          value: "Tag#",
          class: "primary customwhite--text",

        },
        {
          text: "Reason",
          value: "Reason",
          class: "primary customwhite--text",

        },
        {
          text: "Logged By",
          value: "Logged By",
          class: "primary customwhite--text",

        },
        {
          text: "Project",
          value: "Project",
          class: "primary customwhite--text",

        },
      {
        text: "Part#",
        value: "Part#",
        class: "primary customwhite--text",

      },
      {
        text: "Part Description",
        value: "Part Description",
        class: "primary customwhite--text",
      },

      {
        text: "Unit Identifier",
        value: "Unit Identifier",
        class: "primary customwhite--text",
      },
        {
          text: "Order Type",
          value: "Order Type",
          class: "primary customwhite--text",

        },
        {
          text: "Order No",
          value: "Order#",
          class: "primary customwhite--text",

        },

        {
          text: "WayBill",
          value: "WayBill",
          class: "primary customwhite--text",

        },
      

        {
          text: "Packing Slip",
          value: "Packing Slip",
          class: "primary customwhite--text",

        },
        {
          text: "Company",
          value: "Company",
          class: "primary customwhite--text",

        },

        {
          text: "Name",
          value: "Name",
          class: "primary customwhite--text",

        },
        {
          text: "Address",
          value: "Address",
          class: "primary customwhite--text",

        },
        {
          text: "City/State/Zip",
          value: "City/State/Zip",
          class: "primary customwhite--text",

        },
        {
          text: "Country",
          value: "Country",
          class: "primary customwhite--text",

        },
      ],
      filterObj: {
        prj_id: "",
        dsc_tag: "",
        ldate1: "",
        ldate2: "",
        part_num: "",
        unit: "",
        order: "",
        waybill: "",
        packslip: "",
        customer: "",
        address: "",
        city: "",
        zipcode: "",
        status: "",
      },
      orderObj: {
        prj_id: 0,
        order: "",
        rma_vrma: "",
        part_num: "",
        part_desc: "",
        custpo_vendso: "",
        company: "",
        first: "",
        last: "",
        zip: "",
      },
      totalRecords: 0,
      excelFileName: "",
      listTable: [],
      json_fields: {
        "Tag#": "Tag#",
        "Discrepancy Reason": "Discrepancy Reason",
        "Order Type": "Order Type",
        "Order No": "Order#",
        "Packing Slip": "Packing Slip",
        "Project": "Project",
        "Part#": "Part#",
        Qty: "Qty",
        "Date Logged": "Date Logged",
        "Unit Identifier": "Unit Identifier",
        Status: "Status",
        Description: "Desc",
      },
      receiveDataArray: [],
      selectedOrder: [],
      showLineItem: false,
      min3Rule: [
        (v) =>
          (v || "").length >= 3 || "Min Length must be 3 characters or More",
      ],
      required: [(v) => !!v || "Field is required"],
      listHeader: [
        {
          text: "Tag#",
          value: "Tag#",
          class: "primary customwhite--text",
           align: 'start'
        },
        {
          text: "Discrepancy Reason",
          value: "Discrepancy Reason",
          class: "primary customwhite--text",
           align: 'start'
        },
        {
          text: "Order Type",
          value: "Order Type",
          class: "primary customwhite--text",
           align: 'start'
        },
        {
          text: "Order No",
          value: "Order#",
          class: "primary customwhite--text",
           align: 'start'
        },

        {
          text: "Packing Slip",
          value: "Packing Slip",
          class: "primary customwhite--text",
           align: 'start'
        },

        {
          text: "Project",
          value: "Project",
          class: "primary customwhite--text",
           align: 'start'
        },
        

        {
          text: "Part#",
          value: "PartNumber",
          class: "primary customwhite--text",
          align: 'start'
        },
     
        {
          text: "Qty",
          value: "Qty",
          class: "primary customwhite--text",
           align: 'start'
        },
        {
          text: "Date Logged",
          value: "Date Logged",
          class: "primary customwhite--text",
           align: 'start'
        },
        {
          text: "Unit Identifier",
          value: "Unit Identifier",
          class: "primary customwhite--text",
           align: 'start'
        },
        {
          text: "Status",
          value: "Status",
          class: "primary customwhite--text",
           align: 'start'
        },
        {
          text: "Description",
          value: "Desc",
          class: "primary customwhite--text",
           align: 'start'
        },
      ],
      lineItemList: [],
      lineItemHeader: [
        {
          text: "Line#",
          value: "Line#",
          class: "primary customwhite--text",
        },
        {
          text: "Line Type",
          value: "Line Type",
          class: "primary customwhite--text",
        },
        {
          text: "Return Part#",
          value: "Return Part#",
          class: "primary customwhite--text",
        },
        {
          text: "Ware In",
          value: "Ware In",
          class: "primary customwhite--text",
        },

        {
          text: "Avail Qty",
          value: "Avail Qty",
          class: "primary customwhite--text",
        },
      ],
      orderList: [],
      orderHeader: [
        {
          text: "Order",
          value: "Order",
          class: "primary customwhite--text",
        },
        {
          text: "Order Type",
          value: "Order Type",
          class: "primary customwhite--text",
        },
        {
          text: "Project",
          value: "Project",
          class: "primary customwhite--text",
        },
        {
          text: "Order Date",
          value: "Order Date",
          class: "primary customwhite--text",
        },

        {
          text: "Company/Customer",
          value: "Company / Customer",
          class: "primary customwhite--text",
        },

        {
          text: "Project",
          value: "Project",
          class: "primary customwhite--text",
        },
      ],
      showResolve: false,
      showPurge: false,
    };
  },
  async created() {
    this.route = this.$route.path.replace("/", "");
    let data = await AddFavourite.getPageAction(this.userId, this.route);
    this.actionList = data.actionList;
    this.mainPage = data.mainPage;
    this.favouriteList = data.favouriteList;
    this.pageUrl = data.pageUrl;
    this.subPage = data.subPage;
    this.showAdd = data.showAdd;
  },
  computed: {
    computedDateFormattedMomentjs() {
      return this.filterObj.ldate1
        ? moment(this.filterObj.ldate1).format("MM/DD/YYYY")
        : moment(new Date().getTime() - 86400000).format("MM/DD/YYYY");
    },
    computedEndDateFormattedMomentjs() {
      return this.filterObj.ldate2
        ? moment(this.filterObj.ldate2).format("MM/DD/YYYY")
        : moment(new Date()).format("MM/DD/YYYY");
    },
  },

  async mounted() {
    let data = await recevingService.getInitalList("get", this.userId);
    this.projectList = data.Result;
    this.statusList = this.mapStatusesToArray(data?.Result1);
    console.log(this.statusList)
  },
  methods: {
     mapStatusesToArray(data) {
      // Extract the 'Result1' array from the input data
      const resultArray = data;
      // Map the array to the desired structure
      const mappedArray = resultArray.map(item => {
          let key = item.status.charAt(0).toUpperCase(); // Default: first letter of the status in uppercase
  
          // Special case for "Purged"
          if (item.status === "Purged") {
              key = "X";
          }
  
          return {
              status_key: key,
              status: item.status // Keep the full status value
          };
      });
  
      return mappedArray;
  },
    getRowStyle(item) {
      return (
        "background-color:" + item.linecolor + ";padding:0, 10px !important"
      );
    },
    setEndDate() {
      let minEndDate = new Date(this.filterObj.ldate1);
      this.minEndDate = new Date(minEndDate.getTime() + 86400000)
        .toISOString()
        .substr(0, 10);
      let maxSDate = new Date(this.maxStartDate);
      this.maxEndDate = new Date(maxSDate.getTime() + 0)
        .toISOString()
        .substr(0, 10);
    },

    //Show LPN Data on breadcrumb

    //To add and remove From Fav
    addFav() {
      this.showAdd = !this.showAdd;
      AddFavourite.add_remove_Favourite(this.showAdd, this.userId, this.route);
    },
    async onClickSearch(loc) {
      this.loc=loc
      let formValidate =
        loc == "quick"
          ? this.$refs.searchTagForm.validate()
          : this.$refs.filterTagForm.validate();
      if (loc != "quick") {
        this.$refs.searchTagForm.resetValidation();
        this.filterObj.dsc_tag = "";
      }

      if (formValidate) {
        this.filterObj?.ldate2 == ""
          ? (this.filterObj.ldate2 = moment(new Date()).format("MM/DD/YYYY"))
          : "";
        let obj = {
          user_id: this.userId,
          AdvJson: JSON.stringify(this.filterObj),
        };
        let data = await recevingService.postSearch("post", obj, false);
        data.forEach((element) => {
          element["Date Logged"] = utility.convertESTToLocal(
            element["Date Logged"]
          );
        });
        this.listTable = data;
        this.totalRecords = this.listTable.length;
      }
    },
    fileName() {
      this.excelFileName = utility.excelFileName("Discrepancy_Resolution");
    },
    clearFilterObj() {
      this.filterObj = {
        prj_id: "",
        dsc_tag: "",
        ldate1: "",
        ldate2: "",
        part_num: "",
        unit: "",
        order: "",
        waybill: "",
        packslip: "",
        customer: "",
        address: "",
        city: "",
        zipcode: "",
        status: "",
      };
    },
    clearTable() {
      this.listTable = [];
      this.totalRecords = 0;
    },
    onClickClear() {
      this.clearTable();
      this.clearFilterObj();
      this.$refs.searchTagForm.resetValidation();
      this.$refs.filterTagForm.resetValidation();
    },
    async onClickDetails(item) {
      this.line_id = item;

      let getDetailsData = await recevingService.getDiscrepancyDetails(
        "get",
        item
      );
      this.showDetails = true;
      console.log(getDetailsData)
      let logged_by=getDetailsData.Result[0].logged_by.split("@")[0]
      let log_time=getDetailsData.Result[0].log_date
      log_time = utility.convertESTToLocal(
        log_time
      );
      this.log_details=logged_by+" @ "+log_time
      this.receiveDataArray = [
        { label: "Tag#", value: getDetailsData?.Result[0].dsc_tag },
        {
          label: "Discrepancy Status",
          value: getDetailsData.Result[0].order_status,
        },
        { label: "Reason", value: getDetailsData.Result[0].reason },
        { label: "Logged By", value: this.log_details },
      ];
      this.orderStatus=getDetailsData.Result[0].order_status;
      this.enableAction =
        getDetailsData?.Result[0].enable_action == 1 ? true : false;
      this.enableCancel =
        getDetailsData?.Result[0].enable_cancel == 1 ? true : false;
      this.enableClear =
        getDetailsData?.Result[0].enable_clear == 1 ? true : false;
      this.enableComments =
        getDetailsData?.Result[0].enable_comments == 1 ? true : false;
      this.enableGrid =
        getDetailsData?.Result[0].enable_grid == 1 ? true : false;
      this.enableOrder =
        getDetailsData?.Result[0].enable_ordno == 1 ? true : false;
      this.enablePrevent =
        getDetailsData?.Result[0].enable_preventable == 1 ? true : false;
      this.enablePurge =
        getDetailsData?.Result[0].enable_purge == 1 ? true : false;
      this.enableResolve =
        getDetailsData?.Result[0].enable_resolve == 1 ? true : false;
      this.enableUpdate =
        getDetailsData?.Result[0].enable_update == 1 ? true : false;
      this.disableCancel= getDetailsData?.Result[0].enable_update == 0 ? true:false
      this.orderObj.order = getDetailsData?.Result[0].resolve_ordno;
      this.orderObj.prj_id = getDetailsData?.Result[0].project_id;
      this.resolutionList = getDetailsData?.Result2;
      this.preventable = getDetailsData?.Result[0].preventable;
      this.comments = getDetailsData?.Result[0].comments;
      this.resolution = getDetailsData?.Result[0].res_action_key;
      this.disableResolution=getDetailsData?.Result[0].enable_action==0?false:true
      if(this.disableResolution)  
      this.disableResolution=getDetailsData?.Result[0].status=="C" || getDetailsData?.Result[0].status=="X"?false:true,
      this.lineItemList = getDetailsData?.Result1;
      this.selectedOrder=getDetailsData?.Result1  
      //this.selectedOrder?.length > 0? this.disableResolution=false:this.disableResolution=true
      
      this.showLineItem = this.lineItemList?.length > 0 ? true : false;
      this.tableItems=[{
        "Tag#":getDetailsData?.Result[0]?.dsc_tag,
        "Reason":getDetailsData?.Result[0]?.reason,
        "Project":getDetailsData?.Result[0]?.Project,
        "Logged By":this.log_details,
        "Name":getDetailsData?.Result[0]?.Name,
        "Order Number":getDetailsData?.Result[0]?.OrderNumber,
        "Order Type":getDetailsData?.Result[0]?.OrderType,
        "Company":getDetailsData?.Result[0]?.Company,
        "Part#":getDetailsData?.Result[0]?.PartNumber,
        "Part Description":getDetailsData?.Result[0]?.PartDescription,
        "Packing Slip":getDetailsData?.Result[0]?.PackingSlip,
        "Order#":getDetailsData?.Result[0]?.OrderNumber,
        "Unit Identifier":getDetailsData?.Result[0]?.UnitIdentifier,
        "WayBill":getDetailsData?.Result[0]?.Waybill,
        "City/State/Zip":getDetailsData?.Result[0]?.CityStateZip,
        "Address":getDetailsData?.Result[0]?.Address,
        "Country":getDetailsData?.Result[0]?.Country
        


      }]
    },
    onClickCancel() {
      this.showDetails = false;
      this.onClickCloseDetails()
    },
    async onClickSearchOrder() {
      let obj = {
        user_id: this.userId,
        AdvJson: JSON.stringify(this.orderObj),
      };
      let searchData = await recevingService.postOrderSearch(
        "post",
        obj,
        false
      );
      searchData.forEach((element) => {
        element["Order Date"] = utility.convertESTToLocal(
          element["Order Date"]
        );
      });
      this.orderList = searchData;
    },
    onClickTagInfo(){
      this.commonDialog=true;

    },
    onClickCloseDetails() {
      this.clearOrderObj();
      this.showDetails = false;
      this.orderList = [];     
      this.enableAction = false;
      this.enableResolution = true;
      this.enableCancel = false;
      this.enableClear = false;
      this.enableComments = false;
      this.showPurge=false;
      this.showClear=false;
      this.showResolve=false;
      this.enableGrid = false;
      this.resolutionList = [];
      this.lineItemList=[];
      this.resolution = "";
      this.enableOrder = false;
      this.enablePrevent = false;
      this.enablePurge = false;
      this.enableResolve = false;
      this.enableUpdate = false;
    },
    resetDialog() {
      this.onClickDetails(this.line_id);
      this.clearOrderObj()
    },
    closeDialog() {
      this.commonDialog = false;
    },
    clearOrderObj() {
      this.orderObj = {
        prj_id: 0,
        order: "",
        rma_vrma: "",
        part_num: "",
        part_desc: "",
        custpo_vendso: "",
        company: "",
        first: "",
        last: "",
        zip: "",
      };
      this.enableAction = false;
      this.enableCancel = false;
      this.enableClear = false;
      this.enableComments = false;
      this.enableGrid = false;
      this.enableOrder = false;
      this.enablePrevent = false;
      this.enablePurge = false;
      this.enableResolve = false;
      this.enableUpdate = false;
      this.showPurge=false;
      this.showResolve=false;
      this.showClear=false;
    },
    async onClickAction(item) {
      this.disableNotesandPrevent=false;
      this.disableCancel=false;
      this.orderObj.order = "";
      item=="Purge" || item=="Clear"?this.disableResolution=true:""; 
      item=="Purge" ?  (this.selectedOrder = [], this.showLineItem = false) :"";
      item=="Purge" || item=="Clear"? (this.enableComments=false,this.enablePrevent=false):"";
      item=="Clear"  ? (this.resolution="",this.disableNotesandPrevent=true):"";

      // Create a base object with the common key
      let baseObj = { proj_id: this.orderObj.prj_id };

      // Determine the object to send based on the item value
      let obj =
        {
          Purge: { ...baseObj, purge: 1 },
          Clear: { ...baseObj, clear: 1 },

          Resolve: baseObj,
        }[item] || baseObj; // Fallback to baseObj if item doesn't match

      // Set flags based on the item value
      this.showPurge = item === "Purge";
      this.showResolve = item === "Resolve";
      this.enableOrder = item === "Resolve";
     item=="Resolve"?this.enableResolution=true:item=="Clear"?this.enableResolution=false:""
      this.showClear = item === "Clear";
      this.resolution = "";

      // Fetch resolution details
      this.resolutionList = await recevingService.getResolutionDetails(
        "post",
        obj,
        false
      );
    },
    async handleAction(method) {
      const obj = {
        user_id: this.userId,
        method: method,
        line_id: this.line_id,
        so_key: this.soId || 0,
        po_key: this.poId || 0,
        line_key: this.orderLineKey || 0,
        res_action_key: this.resolution,
        preventable: this.preventable ? 1 : 0,
        comments: this.comments,
      };
    
      try {
        let res = await recevingService.postResolveUpdateAction("post", obj, true);
         res=JSON.parse(res)
        if (res.Status==200) {
          this.showDetails = false;
          this.onClickCloseDetails()
          this.onClickSearch(this.loc);
        }
      } catch (error) {
        console.error("Error handling action:", error);
      }
    },
    
    async onClickPurgeAction() {

      await this.handleAction("X");
    },
    
    async onClickResolveAction() {
      await this.handleAction("R");
    },
    
    async onClickClearAction() {
  
      await this.handleAction("C");
    },

    onChangeResolution(){
      this.disableNotesandPrevent?(this.enablePrevent=false,this.enableComments=false):(this.enablePrevent=true,this.enableComments=true)  
   },
   refreshOrder(){  
    this.onClickSearchOrder();
    this.lineItemList=[];
    this.selectedOrder=[];
    this.disableResolution=false;

   },
   refreshLineOrder(){
    this.onClickOrderNum()
    this.selectedOrder=[]
    this.disableResolution=false;

   },
    async onClickOrderNum(item) {
      let obj = {
        dsc_line_id: this.line_id,
        so_key: item["Order Type"] == "SO" ? item.so_id : 0,
        po_key: item["Order Type"] == "PO" ? item.po_id : 0,
      };
      let lineItemsData = await recevingService.getLineItems("post", obj);
      this.lineItemList = lineItemsData;
      this.showLineItem = true;
    },
    onChangeSelectedOrder() {
      if(this.selectedOrder?.length>0){
      this.disableResolution = true;
      this.soId = this.selectedOrder[0]?.so_key;
      this.poId = this.selectedOrder[0]?.po_key;
      this.orderLineKey = this.selectedOrder[0]?.lineID;
}
else{
  this.disableResolution = false;
  this.resolution=""
}
      
    },
    onClickUpdateOrder() {
      this.showDetails = false;
      this.onClickSearch(this.loc);
    },
  },
  components: {
    breadcrumbComp,
    FilterComp,
    DisplayTextData,
    ReadOnlyDialog
  },
};
